(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

/* ========================================================================
* Foto-Sintese JavaScript main engine room
* ====================================================================== */
(function ($, window, document, undefined) {
  var els = {
    $win: $(window),
    $body: $('body'),
    $offCanvas: $('#offcanvas')
  },
      tl = {
    shortcuts: new gsap.timeline({
      paused: true
    })
  },
      a = {
    '*': {
      "in": function _in(next) {
        next();
      },
      out: function out(next) {
        next();
      }
    }
  },
      FS = {
    'common': {
      init: function init() {// dataLayer.push({
        //      'event': 'VirtualPageview',
        //      'virtualPageURL': window.location.pathname,
        //      'virtualPageTitle' : document.title
        // });
      },
      finalize: function finalize() {
        $('#menu-trigger').on('click', function (e) {
          if (!els.$offCanvas.hasClass('active')) {
            gsap.set(els.$offCanvas, {
              autoAlpha: 1
            });
            gsap.to(els.$offCanvas.find('.bg'), 0.3, {
              autoAlpha: 1
            });
            gsap.fromTo(els.$offCanvas.find('.inner'), 0.5, {
              x: '-100%'
            }, {
              x: '0%',
              onComplete: function onComplete() {
                els.$offCanvas.addClass('active');
                els.$body.toggleClass('freeze');
              }
            });
          }
        });
        $('#offcanvas').on('click', function (e) {
          $target = $(e.target);

          if (!$target.closest('.inner').length && els.$offCanvas.hasClass('active')) {
            gsap.fromTo(els.$offCanvas.find('.inner'), 0.5, {
              x: '0%'
            }, {
              x: '-100%',
              onComplete: function onComplete() {
                els.$offCanvas.removeClass('active');
                els.$body.toggleClass('freeze');
              }
            });
            gsap.to(els.$offCanvas.find('.bg'), 0.3, {
              autoAlpha: 0,
              onComplete: function onComplete() {
                gsap.set(els.$offCanvas, {
                  autoAlpha: 0
                });
              }
            });
          }
        });
        $('#offcanvas .menu-item-parent > .menu-item-title').on('click', function (e) {
          var $that = $(this),
              $parent = $that.closest('.menu-item-parent'),
              $subMenu = $parent.find('.sub-menu');
          smh = $subMenu.height();

          if (!$parent.hasClass('open')) {
            gsap.to($parent, 0.5, {
              height: smh + 50,
              onComplete: function onComplete() {
                $parent.addClass('open');
              }
            });
          } else {
            gsap.to($parent, 0.5, {
              height: 50,
              onComplete: function onComplete() {
                $parent.removeClass('open');
              }
            });
          }
        });
      }
    },
    'home': {
      init: function init() {
        var $swiperContainer = $('#main').find('.swiper-container'),
            nSlides = $swiperContainer.find('.swiper-slide').length,
            featuredSS = new Swiper('.swiper-container', {
          loop: nSlides > 1 ? true : false,
          speed: 800,
          autoplay: {
            delay: nSlides > 1 ? 5000 : 0
          },
          pagination: nSlides > 1 ? {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: function renderBullet(index, className) {
              return '<span class="' + className + '"><span class="shape"><svg xmlns="http://www.w3.org/2000/svg" width="27" height="27"><path fill="none" fill-rule="evenodd" stroke="#FFF" stroke-width="1.25" d="M13.5.949L26.051 13.5 13.5 26.051.949 13.5z"/></svg></span><span class="content">' + (index + 1) + '</span></span>';
            }
          } : {},
          on: {
            slideChangeTransitionStart: function slideChangeTransitionStart() {// console.log(this);
            },
            slideChangeTransitionEnd: function slideChangeTransitionEnd() {// console.log(this);
            }
          }
        });
      }
    },
    'photo': {
      init: function init() {
        imagesLoaded('.image', function () {
          gsap.to('figure.hidden', 1, {
            autoAlpha: 1,
            ease: 'power2.inOut'
          });
        });
        tl.shortcuts.fromTo('#instructions-modal', 0.6, {
          autoAlpha: 0
        }, {
          autoAlpha: 1,
          ease: 'power2.inOut'
        });
        $('.actions').find('.button-link').hover(function () {
          var $that = $(this);
          gsap.to($that.find('.shape'), 0.4, {
            fill: '#F6F5EE'
          });
          gsap.to($that.find('.border'), 0.4, {
            fill: '#000'
          });
        }, function () {
          var $that = $(this);
          gsap.to($that.find('.shape'), 0.4, {
            fill: '#000'
          });
          gsap.to($that.find('.border'), 0.4, {
            fill: 'transparent'
          });
        });
      },
      finalize: function finalize() {
        var zoomable = $('.zoomable').magnificPopup({
          type: 'image',
          mainClass: 'mfp-with-zoom',
          zoom: {
            enabled: true,
            duration: 500,
            easing: 'ease-in-out',
            opener: function opener(openerElement) {
              return openerElement.is('img') ? openerElement : openerElement.find('img');
            }
          },
          callbacks: {
            open: function open() {
              // gsap.to( '.zoomable img', 0.2, { autoAlpha: 0 });
              els.$body.addClass('zoomed');
            },
            beforeClose: function beforeClose() {
              // gsap.to( '.zoomable img', 0.1, { autoAlpha: 1, delay: 0.3, ease: 'power2'  });
              els.$body.removeClass('zoomed');
            }
          },
          disableOn: 740,
          tClose: 'Fechar (Esc)',
          tLoading: 'A carregar...',
          image: {
            tError: 'Ocorreu um erro ao carregar a <a href="%url%">imagem</a>.'
          },
          ajax: {
            tError: 'Ocorreu um erro ao efetuaroa <a href="%url%">pedido</a>.'
          }
        }),
            toggleShortcuts = function toggleShortcuts(e) {
          e.preventDefault();

          if (els.$body.hasClass('shortcuts')) {
            tl.shortcuts.reverse();
            els.$body.removeClass('shortcuts');
          } else {
            tl.shortcuts.play(0);
            els.$body.addClass('shortcuts');
          }
        };

        $('#shortcuts').on('click', function (e) {
          toggleShortcuts(e);
        });
        $('#close-instructions-modal').on('click', function (e) {
          if (els.$body.hasClass('shortcuts')) {
            tl.shortcuts.reverse();
            els.$body.removeClass('shortcuts');
          }
        });
        Mousetrap.bind('v', function () {
          var link = $('#back').attr('href');
          window.location = link;
        });
        Mousetrap.bind('z', function () {
          if (els.$body.hasClass('zoomed')) {
            zoomable.magnificPopup('close');
          } else {
            zoomable.magnificPopup('open');
          }
        });
        Mousetrap.bind('a', function () {
          var link = $('.meta .album a').attr('href');
          window.location = link;
        });
        Mousetrap.bind('left', function () {
          var link = $('#previous').attr('href');

          if (link) {
            window.location = link;
          }
        });
        Mousetrap.bind('right', function () {
          var link = $('#next').attr('href');

          if (link) {
            window.location = link;
          }
        });
        Mousetrap.bind('?', function (e) {
          toggleShortcuts(e);
        });
        Mousetrap.bind('esc', function (e) {
          if (els.$body.hasClass('shortcuts')) {
            tl.shortcuts.reverse();
            els.$body.removeClass('shortcuts');
          }
        });
      }
    },
    'category': {
      init: function init() {
        imagesLoaded('.photo', function () {
          gsap.to('figure.hidden', 0.8, {
            autoAlpha: 1,
            ease: 'power2.in',
            stagger: 0.08
          });
        });
      }
    },
    'tag': {
      init: function init() {
        imagesLoaded('.photo', function () {
          gsap.to('figure.hidden', 0.8, {
            autoAlpha: 1,
            ease: 'power2.in',
            stagger: 0.08
          });
        });
      }
    },
    'chronology': {
      init: function init() {
        imagesLoaded('.photo', function () {
          gsap.to('figure.hidden', 0.8, {
            autoAlpha: 1,
            ease: 'power2.in',
            stagger: 0.08
          });
        });
      }
    },
    'child_albuns': {
      init: function init() {
        gsap.set('.album-header .description .button', {
          autoAlpha: 0
        });
        $('.album-header .description').readmore({
          speed: 300,
          collapsedHeight: 190,
          moreLink: '<button class="button open-toggle">Ler mais</button>',
          lessLink: '<button class="button close-toggle">Fechar</button>',
          afterToggle: function afterToggle(trigger, element, expanded) {
            gsap.set($('.album-header .button'), {
              autoAlpha: 1
            });

            if (!expanded) {
              $('html, body').animate({
                scrollTop: 0
              }, {
                duration: 300
              });
            }
          }
        });
        imagesLoaded('.photo', function () {
          gsap.to(['.album-header .description', '.album-header .button'], 0.4, {
            autoAlpha: 1,
            stagger: 0.1,
            ease: 'power2.in'
          });
          gsap.to('figure.hidden', 0.8, {
            autoAlpha: 1,
            delay: 0.4,
            ease: 'power2.in',
            stagger: 0.08
          });
        });
      }
    },
    'albuns': {
      init: function init() {
        var imgLoad = imagesLoaded('.albums');
        imgLoad.on('always', function (instance) {
          gsap.to('.item', 0.8, {
            autoAlpha: 1,
            ease: 'power2.in',
            stagger: 0.08
          });
        });
      }
    },
    'participar': {
      init: function init() {
        var $input = $('#media'),
            $label = $input.next('label'),
            labelVal = $label.html();
        $input.on('change', function (e) {
          var fileName = '';

          if (this.files && this.files.length > 1) {
            fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
          } else if (e.target.value) {
            fileName = e.target.value.split('\\').pop();
          }

          if (fileName) {
            $label.find('span').html(fileName);
          } else {
            $label.html(labelVal);
          }
        }); // Firefox bug fix

        $input.on('focus', function () {
          $input.addClass('has-focus');
        }).on('blur', function () {
          $input.removeClass('has-focus');
        });
      }
    },
    'politica_privacidade': {
      init: function init() {}
    },
    'error404': {
      init: function init() {}
    }
  },
      x = {
    fire: function fire(func, funcname, args) {
      var fire;
      var namespace = FS;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function loadEvents() {
      // Fire common init JS
      x.fire('common'); // Fire page-specific init JS, and then finalize JS

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        x.fire(classnm);
        x.fire(classnm, 'finalize');
      }); // Fire common finalize JS

      x.fire('common', 'finalize');
    }
  }; // swupjs = new Swupjs({
  // 	elements: ['#main'],
  // 	animations: a,
  // 	cache: false,
  // 	preload: false
  // });

  x.loadEvents(); // swupjs.on( 'pageView', x.loadEvents );
})(jQuery, window, document); // Fully reference jQuery after this point.

},{}]},{},[1]);
